import React from 'react';
import { DefaultTemplate } from '@typedbootstrap/ui-kit-pro-components';
import { ToastContainer, toast } from 'react-toastify';
import categories from './categories';
import { Categories, Footer, Header } from './components/organisms';

const App: React.FC = () => {
    const [sprintName, setSprintName] = React.useState<string>('Choose a category to get started!');

    const generateSprintName = (categoryId: string) => {
        const category = categories.find(category => category.id === categoryId);

        if (category) {
            const randomItem = category.items[Math.floor(Math.random() * category.items.length)];

            setSprintName(randomItem);
        }
    };

    const notifyCopy = (text: string, result: boolean) => {
        toast.success(result ? `${text} copied to clipboard!` : 'Failed to copy to clipboard!');
    };

    return (
        <DefaultTemplate footer={<Footer sprintName={sprintName} onCopy={notifyCopy} />}>
            <Header sprintName={sprintName} onCopy={notifyCopy} />
            <Categories data={categories} onCategoryClick={generateSprintName} />
            <ToastContainer />
        </DefaultTemplate>
    );
};

export default App;
