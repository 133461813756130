import React from 'react';
import { AngledHero } from '@typedbootstrap/ui-kit-pro-components';
import { Result } from '../../molecules';

export interface HeaderProps {
    sprintName: string;
    onCopy: (text: string, result: boolean) => void;
}

const Header: React.FC<HeaderProps> = props => {
    const { sprintName, onCopy } = props;

    return (
        <AngledHero
            bg="primary"
            title="Sprint Name Generator"
            text="Let's discover your sprint's new name with this free tool"
            children={<Result className="d-none d-sm-block" value={sprintName} onCopy={onCopy} />}
            dark
            gradient
        />
    );
};

export default Header;
