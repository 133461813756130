import React from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';
import { Result } from '../../molecules';

export interface FooterProps {
    sprintName: string;
    onCopy: (text: string, result: boolean) => void;
}

const Footer: React.FC<FooterProps> = props => {
    const { sprintName, onCopy } = props;

    return (
        <footer className="text-center mb-4">
            <Container>
                Made with <FontAwesomeIcon className="text-red" icon={faHeart} /> by{' '}
                <a href="https://andrewdyer.rocks/" target="_blank">
                    Andrew Dyer
                </a>
            </Container>
            <Result className="d-block d-sm-none fixed-bottom" value={sprintName} onCopy={onCopy} />
        </footer>
    );
};

export default Footer;
