import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

export type Category = {
    id: string;
    name: string;
    items: string[];
};

export interface CategoriesProps {
    data: Category[];
    onCategoryClick: (categoryId: string) => void;
}

const Categories: React.FC<CategoriesProps> = props => {
    const { data, onCategoryClick } = props;

    return (
        <Container>
            <Row className="justify-content-center">
                {data.map((category, index) => (
                    <Col key={index} xs="12" sm="6" md="3">
                        <Button
                            type="button"
                            className="lift mb-4 py-5"
                            color="primary"
                            size="lg"
                            onClick={() => onCategoryClick(category.id)}
                            block>
                            {category.name}
                        </Button>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Categories;
