const categories = [
    {
        id: '1',
        name: 'Most Played Apex Characters',
        items: [
            'Octane',
            'Pathfinder',
            'Bangalore',
            'Wraith',
            'Seer',
            'Bloodhound',
            'Conduit',
            'Lifeline',
            'Horizon',
            'Revenant'
        ]
    },

    {
        id: '2',
        name: 'Popular British Biscuits Brands',
        items: [
            'Wagon Wheels',
            'Malted Milk',
            'Jammie Dodgers',
            'Party Rings',
            'Bourbons',
            'Ginger Nuts',
            'Rich Tea',
            'Hobnobs',
            'Custard Cream',
            'Fingers'
        ]
    },

    {
        id: '3',
        name: 'Richest People in the World',
        items: [
            'Elon Musk',
            'Bernard Arnault',
            'Jeff Bezos',
            'Bill Gates',
            'Mark Zuckerberg',
            'Steve Ballmer',
            'Warren Buffett',
            'Larry Page',
            'Larry Ellison',
            'Sergey Brin'
        ]
    },

    {
        id: '4',
        name: 'Dinosaurs',
        items: [
            'Tyrannosaurus Rex',
            'Spinosaurus',
            'Velociraptor',
            'Triceratops',
            'Ankylosaurus',
            'Stegosaurus',
            'Parasaurolophus',
            'Brachiosaurus',
            'Carcharodontosaurus',
            'Allosaurus'
        ]
    },
    {
        id: '5',
        name: 'Best Beaches in the World',
        items: [
            'Bora Bora',
            'Boracay',
            "Baie d'Audierne",
            'Bali',
            'Bondi',
            'Ipanema',
            'Plage de Tahiti',
            'Acapulco',
            'Porto-Pollo',
            'Woolacombe'
        ]
    },
    {
        id: '6',
        name: 'Most Popular Dog Breeds',
        items: [
            'Labrador Retriever',
            'French Bulldog',
            'German Shepherd',
            'Golden Retriever',
            'Bulldog',
            'Poodle',
            'Beagle',
            'Rottweiler',
            'Yorkshire Terrier',
            'Boxer'
        ]
    },
    {
        id: '7',
        name: 'Types of Apples',
        items: [
            'Granny Smith',
            'Red Delicious',
            'Golden Delicious',
            'Fuji',
            'Gala',
            'Honeycrisp',
            'Braeburn',
            'McIntosh',
            'Cortland',
            'Empire'
        ]
    },
    {
        id: '8',
        name: 'Locations from Fallout 3',
        items: [
            'Megaton',
            'Rivet City',
            'Tenpenny Tower',
            'The Citadel',
            'Vault 101',
            'Arlington Library',
            'The Pitt',
            'The Capitol Building',
            'The White House',
            'The National Archives'
        ]
    },
    {
        id: '9',
        name: 'Best Looney Tunes Characters',
        items: [
            'Bugs Bunny',
            'Daffy Duck',
            'Porky Pig',
            'Elmer Fudd',
            'Yosemite Sam',
            'Sylvester',
            'Wile E. Coyote',
            'Foghorn Leghorn',
            'Marvin the Martian',
            'Barnyard Dawg'
        ]
    }
];

export default categories;
