import React from 'react';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface ResultProps {
    className?: string;
    value: string;
    onCopy: (text: string, result: boolean) => void;
}

const Result: React.FC<ResultProps> = props => {
    const { className, value, onCopy } = props;

    const classNames: string = classnames(
        'bg-white border border-5 border-primary fs-3 mt-4 p-2 text-center text-primary',
        className
    );

    return (
        <CopyToClipboard onCopy={onCopy} text={value}>
            <div className={classNames}>{value}</div>
        </CopyToClipboard>
    );
};

export default Result;
